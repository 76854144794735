<template>
  <a-modal title="专栏购列表" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="52.5%" :footer='null'
    @ok="handleOk" @cancel="handleCancel">
        <div class="zcxy">
            <div class="clearfix table-tools">
                <div class="buttons">
                    <!-- <a-form layout='inline'>
                    <a-form-item>
                        <a-button @click="toExport" icon="export">导出</a-button>
                    </a-form-item>
                    </a-form> -->
                </div>
                <div class="search"></div>
            </div>
            <div class="table">
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
                :columns="columns" :dataSource="list" :scroll="{ x: 1000 }">
                <template slot="index" slot-scope="text, record , index">
                    <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                </template>
            </a-table>
            </div>
            <div class="page">
            <a-pagination
                :pageSizeOptions="pageSizeOptions"
                :total="pageParams.totalCount"
                showSizeChanger
                :pageSize="pageParams.perPage"
                v-model="current"
                @change="changePage"
                @showSizeChange="onShowSizeChange"
                :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
            >
                <template slot='buildOptionText' slot-scope='props'>
                    <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                    <span v-if="props.value==='100'">100条/页</span>
                </template>
                </a-pagination>
            </div>
        </div>
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index',fixed:'left'},
  { title: '学员名称', dataIndex: 'student_name', key: '3' ,fixed:'left'},
//   { title: '手机号码', dataIndex: 'cellphone', key: '4' },
//   { title: '性别', dataIndex: 'gender', key: '5' },
  { title: '交易支付号', dataIndex: 'out_trade_no', key: '6' },
  { title: '支付金额', dataIndex: 'knowledge_cost', key: '7' ,align:"right"},
  { title: '交易时间', dataIndex: 'created_at', key: '8' },
]

import tableMixins from '@/common/mixins/table'
    export default {
        name:'student',
        inject: ['parent'],
        mixins: [ tableMixins ],
        props: {
            item: Object
        },
        data() {
            return {
                loading:false,
                visible: false,
                columns,
                confirmLoading:false,
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
            }
        },
        created () {
            this.visible = true
        },
        beforeDestroy() {
            this.visible = false
        },
        methods: {
            async getList(){
                this.loading = true
                this.searchParams.package_id = this.item.package_id
                let res = await this.$store.dispatch('knowledgePackageMemberAction', { data: this.searchParams })
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            handleOk() {
                
            },
            handleCancel(){
                this.parent.hideStudent(0)
            },
            async toExport(){
                let res = await this.$store.dispatch('weipagesRecruitExportAction', {recruit_id:this.item.class_online_id})
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    link.download = '学员信息.xlsx'
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>