<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="50%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-select placeholder="请选择" showSearch :filterOption="filterOption" mode="multiple"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'content_id',
    label: '内容名称',
    type: 'select',
    rules: [{ required: true, message: '请选择内容名称!' }],
    items: {
      data: 'sList',
      label: 'title',
      value: 'content_id'
    }
  },
]
import url from '@/utils/URL'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      ModalTitle: '新增专栏',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      seleteItems: {
        sList: []
      },
      formData,
    }
  },

  async created() {
    this.visible = true
    this.getSList()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    async getSList() {
      let { package_id } = this.item
      let res = await this.$store.dispatch('knowledgePackageCanRelatedContentAction', {data: {package_id}})
      this.seleteItems.sList = res.items
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.package_id = item.package_id
          }
          let res = await this.$store.dispatch('knowledgeContentRelatedToAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideRelatedModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideRelatedModal(0)
    },
  }
}
</script>
