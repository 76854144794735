<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="42.5%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-form-item :label="item.label" v-for="(item, index) of formData" :key="index" >
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <div v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <div v-if="imageUrl">
            <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
            <div class="close" @click="closeImg($event)">&times;</div>
          </div>
          <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
        </div>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择" :filterOption="filterOption" showSearch @search="handleSearch"
          v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
          v-decorator="[item.name, { rules: item.rules }]"/>
        <l-editor v-else-if="item.type === 'textarea2'" name="package_description" :data="package_description"/>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'package_cover',
    label: '专栏封面(尺寸 750*360)',
    type: 'upload',
    rules: [{ required: true, message: '请输入专栏封面!' }]
  },
  {
    name: 'package_title',
    label: '专栏名称',
    type: 'input',
    rules: [{ required: true, message: '请输入专栏名称!' }]
  },
  {
    name: 'package_price',
    label: '专栏价格',
    type: 'input',
    rules: [{ required: true, message: '请输入专栏价格!' }]
  },
  {
    name: 'sort',
    label: '优先度',
    type: 'input',
    rules: []
  },
  {
    name: 'course_cate_id',
    label: '课程科目',
    type: 'select',
    rules: [],
    items: {
      data: 'classCategorys',
      label: 'filter_name',
      value: 'course_cate_id'
    }
  },
  {
    name: 'package_brief',
    label: '专栏简介',
    type: 'textarea',
    rules: []
  },
  {
    name: 'package_description',
    label: '专栏描述',
    type: 'textarea2',
    rules: []
  },
]
import url from '@/utils/URL'
import ImageModal from '@/views/modal/images'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      ModalTitle: '新增专栏',
      visible: false,
      loading: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: []
      },
      
      imageUrl: '',
      imageRoot: url.imageRoot,
      uploadParams: {},
      imagesVisible: false,
      imageKey: '',

      editor: {},
      package_description: ''
    }
  },

  async created() {
    this.visible = true
    if (this.item) {
      this.loading = true
    }
    await this.$nextTick()
    await this.getClassCategory()
    if (this.item) {
      this.ModalTitle = '编辑专栏'
      let item = await this.getDetail()
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      delete formFields.package_description
      delete formFields.package_cover
      this.package_description = item['package_description']
      this.imageUrl = item.package_cover
      this.form.setFieldsValue(formFields)
      this.loading = false
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    changeEditor(key, value) {
      this.editor[key] = value
    },
    async getDetail() {
      let {package_id} = this.item
      let res = await this.$store.dispatch('knowledgePackageDetailAction', {data: {package_id}})
      return res.data
    },
    async getClassCategory(value) {
      let res = await this.$store.dispatch('searchCourseTypeAction', {params:value})
      this.seleteItems.classCategorys = res.data
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.package_id = item.package_id
          }
          params.package_cover = this.imageUrl
          params.package_description = this.editor.package_description
          await this.$store.dispatch(item ? 'knowledgePackageUpdateAction' : 'knowledgePackageAddAction', { data: params })
          .then(res=>{
            form.resetFields()
            this.parent.hideEditModal(1)
            this.confirmLoading=false;
          })
          .catch(err=>{
            this.confirmLoading=false;
          })
        }
      } catch {
      }
			
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
    },
    showImagesModal(imageKey) { 
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        this.imageUrl = image.path
      }
      this.imagesVisible = false
    },
    handleSearch(value){
      let obj = {"q":value}
      this.getClassCategory(obj)
    }
  }
}
</script>
