<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
      </div>
      <div class="search">
          <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" > 
              <template slot="title">
                <span>课程名称</span>
              </template>
              <a-input allowClear v-model.trim="searchParams.search.title" placeholder="请输入名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="small" :pagination="false" :bordered='false' rowKey="content_id"
        :selection="{key: 'key'}"
        :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}"
        :columns="columns" :dataSource="list">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        <template slot="image" slot-scope="text, record">
          <LImg :src="text" style="width: 100px; height: 42px;"/>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '视频封面', dataIndex: 'knowledge_cover',scopedSlots: { customRender: 'image' },},
  { title: '视频名称', dataIndex: 'title', key: '1' },
  { title: '视频时长', dataIndex: 'knowledge_video_duration', key: '2' },
  // { title: '添加时间', dataIndex: 'created_at', key: '3',align:"right"},
]
import tableMixins from '@/common/mixins/table'
    export default {
        name:"videoModal",
        mixins: [ tableMixins ],
        data() {
            return {
                columns,
                list:[],
                loading:false,
                selectedRowKeys:[],
            }
        },
        props: {
          item: Object
        },
        methods: {
          async getList() {
            this.loading=true;
            this.searchParams.package_id = this.item.package_id
            this.searchParams.search.knowledge_type = 2
            let res = await this.$store.dispatch('knowledgePackageCanRelatedContentAction', {data: this.searchParams})
            this.list = res.items
            this.pageParams = res._meta
            this.loading=false;
          },
          onSelectChange(selectedRowKeys) {
            this.selectedRowKeys = selectedRowKeys
          },
        },
    }
</script>

<style lang="scss" scoped>

</style>