<template>
  <a-modal :title="ModalTitle" v-model="visible"
    cancelText="取消" okText="确定" width="52.5%"
    @ok="handleOk" @cancel="handleCancel" :footer='null'>
    <a-card size="small" style="margin-bottom: 15px;">
      <div class="detail">
        <div class="photo"><LImg :src="detail.package_cover" /></div>
        <div class="text">
          <div class="tit">{{ detail.package_title }}</div>
          <div class="con">{{ detail.package_brief }}</div>
        </div>
      </div>
    </a-card>
    <a-card size="small">
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item>
              <a-button icon="plus" type="primary" @click="showRelatedModal(item)">关联课程</a-button>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="content_id"
          :columns="columns" :dataSource="list">
            <template slot="image" slot-scope="text, record">
              <LImg :src="text" style="width: 100px; height: 42px;"/>
            </template>
            <template slot="duration" slot-scope="text, record">
              <span v-if="record.knowledge_type == 3">{{record.knowledge_mp3_duration}}</span>
              <span v-if="record.knowledge_type == 2">{{record.knowledge_video_duration}}</span>
            </template>
            <template slot="action" slot-scope="text,record,index">
              <div style="position: relative;">
                
                <a href="javascript:;" @click="showSort(index)">排序</a>
                
                <div v-if="isSortModal && sortIndex == index" class="zlgl-sort">
                  <span style="margin-right:5px">排列至第</span>
                  <a-input-number :value="record.content_sort" @change="e=>handleSort(e,record)" :min="1"/>
                  <span style="margin-left:5px">位</span>
                  <a-button @click="handlesortOk(record)" :loading="confirmLoading" style="margin-left:5px" type="primary">确认</a-button>
                  <a-button @click="hideRelatedModal" style="margin-left:5px">取消</a-button>
                </div>
                <a-divider type="vertical" />
                <a href="javascript:;" @click="deleteItem(record)">取消关联</a>
                
              </div>
            </template>
        </a-table>
      </div>
      <div class="page">
          <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
          >
              <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
              </template>
          </a-pagination>
      </div>
    </a-card>
    <!-- <relatedModal v-if="relatedVisible" :item="modalData"/> -->
    <related v-if="relatedVisible" :item="modalData"/>
  </a-modal>
</template>
<style lang="scss" scoped>
.detail {
  display: flex;
  .photo {
    width: 220px;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  .text {
    flex: 1;
    font-size: 14px;
    .tit {
      font-size: 20px;
    }
  }
}
.zlgl-sort{
  position: absolute;
  right:0;
  top:-13px;
  width:330px;
  background:#fff;
  padding:5px;
}
</style>

<script>
import relatedModal from './relatedModal'
import related from './related'
import tableMixins from '@/common/mixins/table'
const columns = [
  { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index'},
  { title: '内容封面(尺寸 750*360)', dataIndex: 'knowledge_cover',scopedSlots: { customRender: 'image' }, key: 'name' },
  { title: '内容标题', dataIndex: 'content_title', key: '1' },
  { title: '内容类型', dataIndex: 'knowledge_type_name', key: '2' },
  { title: '内容时长', dataIndex: 'knowledge_video_duration', key: '3' ,scopedSlots: { customRender: 'duration' }},
  { title: '操作', key: 'operation', width: 120, scopedSlots: { customRender: 'action' },align:"right"},
]

export default {
  name: 'EditModal',
  provide() {
    return {
      parent: this
    }
  },
  inject: ['parent'],
  props: {
    item: Object
  },

  components: {
    related,
    relatedModal,
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      ModalTitle: '专栏详情',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      detail: {},
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      relatedVisible: false,
      isSortModal: false,
      sortIndex:-1,
    }
  },
  mixins:[tableMixins],
  async created() {
    this.visible = true
    this.getDetail()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getDetail() {
      let {package_id} = this.item
      let res = await this.$store.dispatch('knowledgePackageDetailAction', {data: {package_id}})
      this.detail = res.data
    },
    async handleOk() {
      this.confirmLoading=true;
      this.parent.hideDetailModal(0)
    },
    handleCancel() {
      this.parent.hideDetailModal(0)
    },
    async getList() {
      this.loading = true
      let {package_id} = this.item
      this.searchParams.package_id = package_id
      let res = await this.$store.dispatch('knowledgePackageContentAction', { data: this.searchParams})
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    showRelatedModal(item) {
      this.modalData = item
      this.relatedVisible = true
    },
    hideRelatedModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.relatedVisible = false
      this.isSortModal = false
    },
    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要取消关联"${item.content_title}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('knowledgeContentCancelRelatedAction', { data: { package_id: that.item.package_id, content_id: item.content_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    showSort(index){
      this.isSortModal = true,
      this.sortIndex = index
    },
    handleSort(e,record){
      record.content_sort = e
    },
    async handlesortOk(item){
      let obj = {
        package_id : this.item.package_id,
        content_id : item.content_id,
        order_index : item.content_sort
      }
      this.confirmLoading = true
      await this.$store.dispatch('knowledgeContentChangeOrderAction', { data: obj })
      .then(res=>{
          this.$message.success('操作成功~')
          this.getList()
          this.isSortModal = false
          this.confirmLoading = false
      })
      .catch(err=>{
          this.$message.error('操作失败~')
          this.confirmLoading = false
      })
    }
  }
}
</script>
