<template>
    <a-modal title="" v-model="visible" :footer='null' width="650px" class="videoModal"
     cancelText="取消" okText="保存" @ok="handleOk" @cancel="handleCancel">
        <div id="dplayer"></div>
  </a-modal>
</template>

<script>
import DPlayer from 'dplayer';
// import Hls from 'hls.js'
export default {
    name:'videoModal',
    inject: ['parent'],
    data() {
        return {
            visible: false
        }
    },
    props: {
        videoSrc: String,
    },
    created () {
        // console.log(this.videoSrc,'videoSrc')
        this.visible = true
        this.$nextTick(() => {
            if(!this.videoSrc)
                return true;
            if(this.videoSrc){
                let playerOtions =  {
                    container: document.getElementById('dplayer'),
                    autoplay:true,
                    live: false,
                    muted: true,
                    screenshot:true,
                    playbackSpeed:false,
                    preload: 'auto',
                    contextmenu:[],
                    highlight:[],
                    video: {
                        url: this.videoSrc,
                    }
                }
                new DPlayer(playerOtions)
            }
        })
        
    },
    methods: {
        handleOk(){},
        handleCancel() {
            this.parent.videoShow=false;
        }
    },
}
</script>

<style lang="scss">
    .videoModal{
        .ant-modal-content{
            background-color:inherit;
            box-shadow:none;
        }
        .ant-modal-body{
            padding: 40px;
        }
    }
</style>