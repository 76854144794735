<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">新增专栏</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" > 
              <template slot="title">
                <span>专栏名称</span>
              </template>
              <a-input allowClear v-model.trim="searchData.package_title" placeholder="专栏名称" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>

          <!-- <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>专栏状态</span>
              </template>
              <a-select allowClear v-model="searchData.package_status" placeholder="请选择专栏状态" style="width: 140px">
                <a-select-option v-for="(d, index) of packageStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item> -->

          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>添加时间</span>
              </template>
              <a-range-picker
              style="width: 240px"
              :ranges="rangesData"
              v-model='searchData.add'
              allowClear
              @change="(val,time)=>handleTime(val,time,'add')" />
            </a-tooltip>
          </a-form-item>

          <a-form-item>
            <a-radio-group :value="searchData.package_status">
              <a-radio-button value="1" @click="handleColButChange">
                  售卖中
              </a-radio-button>
              <a-radio-button value="0" @click="handleColButChange">
                  已下架
              </a-radio-button>
            </a-radio-group>
          </a-form-item>

          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="package_id"
        :columns="columns" :dataSource="list" :scroll="{ x: 1400 }">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>
        
        <template slot="image" slot-scope="text, record">
          <LImg :src="text" style="width: 100px; height: 42px; margin: 0 5px 5px 0"/>
        </template>

        <template slot="package_title" slot-scope="text,record">
            <a href="javascript:;" @click="showDetailModal(record)">{{text}}</a>
        </template>

        <template slot="package_count" slot-scope="text,record">
            <a href="javascript:;" @click="showDetailModal(record)">{{text}}</a>
        </template>

        <template slot="package_sold_count" slot-scope="text,record">
            <a href="javascript:;" @click="showStudent(record)">{{text}}</a>
        </template>

        <template slot="status" slot-scope="text,record">
          <a-switch checkedChildren="售卖中" unCheckedChildren="已下架" :checked="record.package_status == '1'" @change="changeStatus(record)"/>
        </template>

        <template slot="action" slot-scope="text,record">
          <!-- <a href="javascript:;">复制链接</a>
          <a-divider type="vertical" />
          <a href="javascript:;">购买列表</a>
          <a-divider type="vertical" /> -->
          <a v-if="canRead" href="javascript:;" @click="showDetailModal(record)">详情</a>
          <a-divider v-if="canRead" type="vertical" />
          <a v-if="canUpdate" href="javascript:;" @click="showEditModal(record)">编辑</a>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
    <detailModal v-if="detailVisible" :item="modalData"/>
    <student v-if="isStudent" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index', fixed: 'left'},
  { title: '专栏封面(尺寸 750*360)', dataIndex: 'package_cover',scopedSlots: { customRender: 'image' }, key: 'name' },
  { title: '专栏名称', dataIndex: 'package_title',  key: '1' ,ellipsis:true, scopedSlots: { customRender: 'package_title' }  },
  { title: '专栏内容数', dataIndex: 'package_count',align:'right', key: '2', scopedSlots: { customRender: 'package_count' }  },
  { title: '专栏购买数', dataIndex: 'package_sold_count', key: '5',align:'right' ,scopedSlots: { customRender: 'package_sold_count' }},
  { title: '专栏价格', dataIndex: 'package_price', key: '4' ,align:'right'},
  { title: '添加时间', dataIndex: 'created_at', key: '9' ,align:'right'},
  { title: '专栏状态', dataIndex: 'package_status', key: '10',align:'right', scopedSlots: { customRender: 'status' } },
  { title: '操作', key: 'operation', width: 140, align: 'right', scopedSlots: { customRender: 'action' }},
]

import moment from 'moment'
import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import detailModal from './detailModal'
import student from './student'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    student,
    editModal,
    detailModal,
  },

  data() {
    return {
      loading: false,
      isStudent:false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      packageStatus: C_ITEMS.packageStatus,
      searchData: {
      },
      visible: false,
      modalData: {},
      editVisible: false,
      detailVisible: false,
      authType:['market','content'],
    }
  },
  mixins: [ authority , tableMixins, ranges],

  methods: {
    closeSearch() {
      this.visible = false
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'add') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('knowledgePackageAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    handleColButChange(e){
        if(this.searchData.package_status == e.target.value){
            this.searchData.package_status = ""
        }else{
            this.searchData.package_status = e.target.value
        }
        this.getList();
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    showDetailModal(item) {
      this.modalData = item
      this.detailVisible = true
    },
    hideDetailModal(type) {
      this.detailVisible = false
    },
    showStudent(val){
      this.modalData = val
      this.isStudent = true
    },
    hideStudent(val){
      this.isStudent = false
    },
    changeStatus(record) {
      if (record.package_status == '1') {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    },
    
    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要下线"${item.package_title}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('knowledgePackageDeleteAction', { data: { package_id: item.package_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    recoveryItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要上线"${item.package_title}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('knowledgePackageRecoveryAction', { data: { package_id: item.package_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    handleTime(val,time,name){
        this.searchData[name] = val
        this.$forceUpdate()
    },
  }
}
</script>
