<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>市场应用</a-breadcrumb-item>
      <a-breadcrumb-item>课程专栏</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        <a-tab-pane key="1">
          <span slot="tab">专栏管理<a-badge :count="0" /></span>
          <zlgl v-if="activeKey==='1'"/>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">内容管理<a-badge :count="0" /></span>
          <nrgl v-if="activeKey==='2'"/>
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">评论列表<a-badge :count="0" /></span>
          <pjlb v-if="activeKey==='3'"/>
        </a-tab-pane>

        <a-tab-pane key="4">
          <span slot="tab">焦点图设置<a-badge :count="0" /></span>
          <banner v-if="activeKey==='4'"/>
        </a-tab-pane>
        <!-- <a-tab-pane key="5">
          <span slot="tab">购买记录<a-badge :count="0" /></span>
          <gmjl v-if="activeKey==='5'"/>
        </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import zlgl from './zlgl/index'
import nrgl from './nrgl/index'
import pjlb from './pjlb/index'
import sjyl from './sjyl/index'
import gmjl from './gmjl/index'
import banner from '@/views/orginization/studio/jdtgl'

export default {
  components: {
    zlgl,
    nrgl,
    pjlb,
    sjyl,
    gmjl,
    banner
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

