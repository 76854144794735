<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item v-if="canSave">
            <a-dropdown>
              <a-menu slot="overlay">
                <!-- <a-menu-item key="1" @click="showEditModal()">添加图文</a-menu-item> -->
                <a-menu-item key="2" @click="showEditModal2()">添加视频</a-menu-item>
                <a-menu-item key="3" @click="showEditModal3()">添加音频</a-menu-item>
              </a-menu>
              <a-button type="primary"> 新增内容 <a-icon type="down" /> </a-button>
            </a-dropdown>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>内容标题</span>
              </template>
              <a-input allowClear v-model.trim="searchData.title" placeholder="内容标题" style="width: 160px"/>
            </a-tooltip>
          </a-form-item>

          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>添加时间</span>
              </template>
              <a-range-picker
              style="width: 240px"
              :ranges="rangesData"
              v-model='searchData.add'
              allowClear
              @change="(val,time)=>handleTime(val,time,'add')" />
            </a-tooltip>
          </a-form-item>

          <!-- <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>内容类型</span>
              </template>
              <a-select allowClear v-model="searchData.knowledge_type" placeholder="请选择内容类型" style="width: 140px">
                <a-select-option v-for="(d, index) of knowledgeTypes" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item> -->

            <a-form-item>
            <a-radio-group :value="searchData.knowledge_type">
              <a-radio-button value="2" @click="handleTypeButChange">
                  视频
              </a-radio-button>
              <a-radio-button value="3" @click="handleTypeButChange">
                  音频
              </a-radio-button>
            </a-radio-group>
          </a-form-item>


          <a-form-item>
            <a-radio-group :value="searchData.content_status">
              <a-radio-button value="1" @click="handleColButChange">
                  售卖中
              </a-radio-button>
              <a-radio-button value="0" @click="handleColButChange">
                  已下架
              </a-radio-button>
            </a-radio-group>
          </a-form-item>

          <!-- <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>内容状态</span>
              </template>
              <a-select allowClear v-model="searchData.content_status" placeholder="请选择内容状态" style="width: 140px">
                <a-select-option v-for="(d, index) of contentStatus" :key="index" :value="d['value']">{{ d['label'] }}</a-select-option>
              </a-select>
            </a-tooltip>
          </a-form-item> -->          

          <a-form-item v-if="canIndex">
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="content_id"
        :columns="columns" :dataSource="list">
        <template slot="index" slot-scope="text, record , index">
          <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
        </template>

        <template slot="status" slot-scope="text,record">
          <a-switch checkedChildren="售卖中" unCheckedChildren="已下架" :checked="record.content_status == '1'" @change="changeStatus(record)"/>
        </template>

        <template slot="content_title" slot-scope="text,record">
          <a @click="showContent(record)">{{text}}</a>
        </template>

        <template slot="image" slot-scope="text, record">
            <LImg :src="text" style="width: 100px; height: 42px;"/>
          </template>
          <template slot="action" slot-scope="text,record">
            <!-- <a href="javascript:;">购买列表</a>
            <a-divider type="vertical" />
            <a href="javascript:;">预览内容</a>
            <a-divider type="vertical" /> -->
            <a v-if="canUpdate" href="javascript:;" @click="showEditModal(record)">编辑</a>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <videoModal v-if="videoShow" :videoSrc="videoSrc"/>
    <editModal v-if="editVisible" :item="modalData"/>
    <editModal2 v-if="editVisible2" :item="modalData"/>
    <editModal3 v-if="editVisible3" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index' },
  { title: '内容封面(尺寸 750*360)',dataIndex: 'knowledge_cover',scopedSlots: { customRender: 'image' }, key: 'name',ellipsis:true },
  { title: '内容标题', dataIndex: 'title', key: 'title' ,ellipsis:true,scopedSlots: { customRender: 'content_title' }},
  { title: '内容类型', dataIndex: 'knowledge_type', key: '2' },
  // { title: '购买数量', dataIndex: 'knowledge_sold_count', key: '4' ,align:"center"},
  // { title: '浏览数', dataIndex: 'view_count', key: '5' ,align:"center"},
  { title: '评论数', dataIndex: 'comment_count', key: '6' ,align:"center"},
  // { title: '优先度', dataIndex: 'sort', key: '7' },
  { title: '内容大小', dataIndex: 'size', key: '3' ,align:"center"},
  { title: '添加人', dataIndex: 'created_by', key: 'created_by' },
  { title: '添加时间', dataIndex: 'created_at', key: '9' },
  { title: '内容状态', dataIndex: 'content_status', key: 'content_status', scopedSlots: { customRender: 'status' } },
  { title: '操作', key: 'operation', width: 100, align: 'right', scopedSlots: { customRender: 'action' }},
]

import moment from 'moment'
import videoModal from './videoModal'
import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import editModal2 from './editModal2'
import editModal3 from './editModal3'
import ranges from "@/common/mixins/ranges"
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'

export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    videoModal,
    editModal,
    editModal2,
    editModal3,
  },

  data() {
    return {
      loading: false,
      videoSrc:'',
      videoShow: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      knowledgeTypes: C_ITEMS.knowledgeTypes,
      contentStatus: C_ITEMS.contentStatus,
      searchData: {},
      visible: false,
      modalData: {},
      editVisible: false,
      editVisible2: false,
      editVisible3: false,
      authType:['market','content'],
    }
  },
  mixins: [ authority, tableMixins, ranges ],

  methods: {
    closeSearch() {
      this.visible = false
    },
    showContent(record){
        this.videoSrc = record.content_video;
        this.videoShow=true;
        // console.log(record);
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'add') {
            obj['start_date'] = searchData[k][0]?moment(searchData[k][0]).format('YYYY-MM-DD'):''
            obj['end_date'] = searchData[k][1]?moment(searchData[k][1]).format('YYYY-MM-DD'):''
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('knowledgeContentAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    handleTypeButChange(e){
        if(this.searchData.knowledge_type == e.target.value){
            this.searchData.knowledge_type = ""
        }else{
            this.searchData.knowledge_type = e.target.value
        }
        this.getList();
    },
    handleColButChange(e){
        if(this.searchData.content_status == e.target.value){
            this.searchData.content_status = ""
        }else{
            this.searchData.content_status = e.target.value
        }
        this.getList();
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    showEditModal2(item) {
      this.modalData = item
      this.editVisible2 = true
    },
    hideEditModal2(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible2 = false
    },
    showEditModal3(item) {
      this.modalData = item
      this.editVisible3 = true
    },
    hideEditModal3(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible3 = false
    },
    changeStatus(record) {
      if (record.content_status == '1') {
        this.deleteItem(record)
      } else {
        this.recoveryItem(record)
      }
    },
    handleTime(val,time,name){
        this.searchData[name] = val
        this.$forceUpdate()
    },

    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要下线"${item.title}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('knowledgeContentDeleteAction', { data: { content_id: item.content_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    recoveryItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要上线"${item.title}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('knowledgeContentRecoveryAction', { data: { content_id: item.content_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
  }
}
</script>
