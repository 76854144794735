<template>
  <a-modal title="关联课程" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="37.5%"
    @ok="handleOk" @cancel="handleCancel">
        <a-tabs v-model="activeIndex">
            
            <a-tab-pane key="1" tab="视频内容">
                <videoModal ref='videoModal' :item='item'/>
            </a-tab-pane>

            <a-tab-pane key="2" tab="音频内容">
                <audioModal ref='audioModal' :item='item'/>
            </a-tab-pane>
        </a-tabs>
  </a-modal>
</template>

<script>
import audioModal from './audioModal'
import videoModal from './videoModal'
    export default {
        name:"related",
        inject: ['parent'],
        data() {
            return {
                visible: false,
                confirmLoading:false,
                activeIndex:'1'
            }
        },
        props: {
            item: Object
        },
        components:{
            audioModal,
            videoModal
        },
        created () {
            this.visible = true
        },
        beforeDestroy () {
            this.visible = false
        },
        methods: {
            callback(){

            },
            async handleOk() {
                this.confirmLoading = true
                try {
                    let arr = []
                    if(this.activeIndex == 1){
                        arr = this.$refs.videoModal.selectedRowKeys
                    }else{
                        arr = this.$refs.audioModal.selectedRowKeys
                    }
                    if (arr.length != 0) {
                        let obj = {
                            package_id:this.item.package_id,
                            content_id:arr
                        } 
                        let res = await this.$store.dispatch('knowledgeContentRelatedToAction', { data: obj })
                        if(res) {
                            this.$message.success('操作成功~')
                            this.parent.hideRelatedModal(1)
                        }
                    }else{
                        this.$message.warning('请选择!!')
                    }
                } catch {
                }
                this.confirmLoading=false;
            },
            handleCancel(){
                this.parent.hideRelatedModal()
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>