<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="42.5%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <div v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <div v-if="imageUrl">
            <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
            <div class="close" @click="closeImg($event)">&times;</div>
          </div>
          <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
        </div>
        <!-- <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/> -->
        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
        <l-editor v-else-if="item.type === 'textarea2'" name="package_description" :data="package_description"/>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'knowledge_cover',
    label: '内容封面(尺寸 750*360)',
    type: 'upload',
    rules: [{ required: true, message: '请输入专栏封面!' }]
  },
  {
    name: 'title',
    label: '内容标题',
    type: 'input',
    rules: [{ required: true, message: '请输入内容标题!' }]
  },
  // {
  //   name: 'price',
  //   label: '付费价格',
  //   type: 'input',
  //   rules: [{ required: true, message: '请输入付费价格!' }]
  // },
  // {
  //   name: 'sort',
  //   label: '优先度',
  //   type: 'input',
  //   rules: []
  // },
  // {
  //   name: 'course_cate_id',
  //   label: '关联科目',
  //   type: 'select',
  //   rules: [],
  //   items: {
  //     data: 'classCategorys',
  //     label: 'filter_name',
  //     value: 'course_cate_id'
  //   }
  // },
  {
    name: 'content',
    label: '内容简介',
    type: 'textarea2',
    rules: []
  },
]
import url from '@/utils/URL'
import ImageModal from '@/views/modal/images'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      ModalTitle: '新增内容',
      loading: false,
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: []
      },
      
      imageUrl: '',
      imageRoot: url.imageRoot,
      uploadParams: {},
      imagesVisible: false,
      imageKey: '',
      
      package_description: '',
      editor: {},
    }
  },

  async created() {
    this.visible = true
    this.loading = true;
    await this.$nextTick()
    await this.getClassCategory()
    if (this.item) {
      this.ModalTitle = '编辑内容'
      let item = await this.getDetail()
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      this.imageUrl = item.knowledge_cover
      this.package_description = item['content']
      this.form.setFieldsValue(formFields)
    }else{
      this.loading=false;
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getClassCategory() {
      let res = await this.$store.dispatch('searchCourseTypeAction', {})
      this.seleteItems.classCategorys = res.data
    },
    async getDetail() {
      this.loading=true;
      let res = await this.$store.dispatch('knowledgeContentDetailAction', {data: {content_id: this.item.content_id}})
      this.loading=false;
      return res.data

    },
    changeEditor(key, value) {
      this.editor[key] = value
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.content_id = item.content_id
          }
          params.knowledge_cover = this.imageUrl
          params.content = this.editor.package_description
          params.knowledge_type = 1
          let res = await this.$store.dispatch(item ? 'knowledgeContentUpdateAction' : 'knowledgeContentAddAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideEditModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
    },
    showImagesModal(imageKey) { 
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        this.imageUrl = image.path
      }
      this.imagesVisible = false
    },
  }
}
</script>
